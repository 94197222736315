exports.components = {
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-404-mdx": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/404.mdx" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-404-mdx" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-articles-index-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/articles/index.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-articles-index-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-auth-0-callback-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/auth0_callback.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-auth-0-callback-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-blog-index-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/blog/index.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-blog-index-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-demo-mdx": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/demo.mdx" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-demo-mdx" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-templates-category-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/templates/category.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-templates-category-js" */),
  "component---src-pages-animation-ball-game-mdx": () => import("./../../../src/pages/animation/ball-game.mdx" /* webpackChunkName: "component---src-pages-animation-ball-game-mdx" */),
  "component---src-pages-animation-enter-update-exit-mdx": () => import("./../../../src/pages/animation/enter-update-exit.mdx" /* webpackChunkName: "component---src-pages-animation-enter-update-exit-mdx" */),
  "component---src-pages-animation-game-loops-mdx": () => import("./../../../src/pages/animation/game-loops.mdx" /* webpackChunkName: "component---src-pages-animation-game-loops-mdx" */),
  "component---src-pages-animation-index-mdx": () => import("./../../../src/pages/animation/index.mdx" /* webpackChunkName: "component---src-pages-animation-index-mdx" */),
  "component---src-pages-animation-redux-animation-mdx": () => import("./../../../src/pages/animation/redux-animation.mdx" /* webpackChunkName: "component---src-pages-animation-redux-animation-mdx" */),
  "component---src-pages-animation-simple-animation-mdx": () => import("./../../../src/pages/animation/simple-animation.mdx" /* webpackChunkName: "component---src-pages-animation-simple-animation-mdx" */),
  "component---src-pages-articles-3-key-insights-that-make-d-3-js-easy-to-learn-index-mdx": () => import("./../../../src/pages/articles/3-key-insights-that-make-d3js-easy-to-learn/index.mdx" /* webpackChunkName: "component---src-pages-articles-3-key-insights-that-make-d-3-js-easy-to-learn-index-mdx" */),
  "component---src-pages-articles-a-drilldown-piechart-with-react-and-d-3-index-mdx": () => import("./../../../src/pages/articles/a-drilldown-piechart-with-react-and-d3/index.mdx" /* webpackChunkName: "component---src-pages-articles-a-drilldown-piechart-with-react-and-d-3-index-mdx" */),
  "component---src-pages-articles-a-trick-to-make-your-big-dataviz-load-super-fast-index-mdx": () => import("./../../../src/pages/articles/a-trick-to-make-your-big-dataviz-load-super-fast/index.mdx" /* webpackChunkName: "component---src-pages-articles-a-trick-to-make-your-big-dataviz-load-super-fast-index-mdx" */),
  "component---src-pages-articles-advent-of-code-day-22-sporifica-virus-index-mdx": () => import("./../../../src/pages/articles/advent-of-code-day-22-sporifica-virus/index.mdx" /* webpackChunkName: "component---src-pages-articles-advent-of-code-day-22-sporifica-virus-index-mdx" */),
  "component---src-pages-articles-animated-string-diffing-with-react-and-d-3-index-mdx": () => import("./../../../src/pages/articles/animated-string-diffing-with-react-and-d3/index.mdx" /* webpackChunkName: "component---src-pages-articles-animated-string-diffing-with-react-and-d-3-index-mdx" */),
  "component---src-pages-articles-animating-with-react-redux-and-d-3-index-mdx": () => import("./../../../src/pages/articles/animating-with-react-redux-and-d3/index.mdx" /* webpackChunkName: "component---src-pages-articles-animating-with-react-redux-and-d-3-index-mdx" */),
  "component---src-pages-articles-announcing-d-3-blackbox-used-3-index-mdx": () => import("./../../../src/pages/articles/announcing-d3blackbox-used3/index.mdx" /* webpackChunkName: "component---src-pages-articles-announcing-d-3-blackbox-used-3-index-mdx" */),
  "component---src-pages-articles-behind-the-curve-of-my-bar-donut-chart-index-mdx": () => import("./../../../src/pages/articles/behind-the-curve-of-my-bar-donut-chart/index.mdx" /* webpackChunkName: "component---src-pages-articles-behind-the-curve-of-my-bar-donut-chart-index-mdx" */),
  "component---src-pages-articles-build-responsive-svg-layouts-with-reactsvgflexbox-index-mdx": () => import("./../../../src/pages/articles/build-responsive-svg-layouts-with-reactsvgflexbox/index.mdx" /* webpackChunkName: "component---src-pages-articles-build-responsive-svg-layouts-with-reactsvgflexbox-index-mdx" */),
  "component---src-pages-articles-buy-a-tree-index-mdx": () => import("./../../../src/pages/articles/buy-a-tree/index.mdx" /* webpackChunkName: "component---src-pages-articles-buy-a-tree-index-mdx" */),
  "component---src-pages-articles-candy-index-mdx": () => import("./../../../src/pages/articles/candy/index.mdx" /* webpackChunkName: "component---src-pages-articles-candy-index-mdx" */),
  "component---src-pages-articles-christmas-carols-index-mdx": () => import("./../../../src/pages/articles/christmas-carols/index.mdx" /* webpackChunkName: "component---src-pages-articles-christmas-carols-index-mdx" */),
  "component---src-pages-articles-christmas-gifts-index-mdx": () => import("./../../../src/pages/articles/christmas-gifts/index.mdx" /* webpackChunkName: "component---src-pages-articles-christmas-gifts-index-mdx" */),
  "component---src-pages-articles-christmas-movies-index-mdx": () => import("./../../../src/pages/articles/christmas-movies/index.mdx" /* webpackChunkName: "component---src-pages-articles-christmas-movies-index-mdx" */),
  "component---src-pages-articles-christmas-trees-index-mdx": () => import("./../../../src/pages/articles/christmas-trees/index.mdx" /* webpackChunkName: "component---src-pages-articles-christmas-trees-index-mdx" */),
  "component---src-pages-articles-corona-simulation-mdx": () => import("./../../../src/pages/articles/corona-simulation.mdx" /* webpackChunkName: "component---src-pages-articles-corona-simulation-mdx" */),
  "component---src-pages-articles-creating-the-perfect-rounded-edge-with-d-3-curves-index-mdx": () => import("./../../../src/pages/articles/creating-the-perfect-rounded-edge-with-d3-curves/index.mdx" /* webpackChunkName: "component---src-pages-articles-creating-the-perfect-rounded-edge-with-d-3-curves-index-mdx" */),
  "component---src-pages-articles-dancing-tree-fractal-react-index-mdx": () => import("./../../../src/pages/articles/dancing-tree-fractal-react/index.mdx" /* webpackChunkName: "component---src-pages-articles-dancing-tree-fractal-react-index-mdx" */),
  "component---src-pages-articles-dataviz-saves-lives-covid-19-mdx": () => import("./../../../src/pages/articles/dataviz-saves-lives-covid19.mdx" /* webpackChunkName: "component---src-pages-articles-dataviz-saves-lives-covid-19-mdx" */),
  "component---src-pages-articles-declarative-d-3-charts-with-react-163-index-mdx": () => import("./../../../src/pages/articles/declarative-d3-charts-with-react-163/index.mdx" /* webpackChunkName: "component---src-pages-articles-declarative-d-3-charts-with-react-163-index-mdx" */),
  "component---src-pages-articles-declarative-d-3-transitions-with-react-163-index-mdx": () => import("./../../../src/pages/articles/declarative-d3-transitions-with-react-163/index.mdx" /* webpackChunkName: "component---src-pages-articles-declarative-d-3-transitions-with-react-163-index-mdx" */),
  "component---src-pages-articles-dinosaurs-software-engineers-and-cholera-what-do-they-have-in-common-index-mdx": () => import("./../../../src/pages/articles/dinosaurs-software-engineers-and-cholera-what-do-they-have-in-common/index.mdx" /* webpackChunkName: "component---src-pages-articles-dinosaurs-software-engineers-and-cholera-what-do-they-have-in-common-index-mdx" */),
  "component---src-pages-articles-easy-d-3-blackbox-components-with-react-hooks-index-mdx": () => import("./../../../src/pages/articles/easy-d3-blackbox-components-with-react-hooks/index.mdx" /* webpackChunkName: "component---src-pages-articles-easy-d-3-blackbox-components-with-react-hooks-index-mdx" */),
  "component---src-pages-articles-experimenting-with-the-new-react-concurrent-mode-index-mdx": () => import("./../../../src/pages/articles/experimenting-with-the-new-react-concurrent-mode/index.mdx" /* webpackChunkName: "component---src-pages-articles-experimenting-with-the-new-react-concurrent-mode-index-mdx" */),
  "component---src-pages-articles-force-directed-graphs-with-react-and-d-3-v-7-index-mdx": () => import("./../../../src/pages/articles/force-directed-graphs-with-react-and-d3v7/index.mdx" /* webpackChunkName: "component---src-pages-articles-force-directed-graphs-with-react-and-d-3-v-7-index-mdx" */),
  "component---src-pages-articles-fractals-in-react-index-mdx": () => import("./../../../src/pages/articles/fractals-in-react/index.mdx" /* webpackChunkName: "component---src-pages-articles-fractals-in-react-index-mdx" */),
  "component---src-pages-articles-game-loop-hooks-mdx": () => import("./../../../src/pages/articles/game-loop-hooks.mdx" /* webpackChunkName: "component---src-pages-articles-game-loop-hooks-mdx" */),
  "component---src-pages-articles-gift-stockings-index-mdx": () => import("./../../../src/pages/articles/gift-stockings/index.mdx" /* webpackChunkName: "component---src-pages-articles-gift-stockings-index-mdx" */),
  "component---src-pages-articles-histogram-in-d-3-v-3-vs-d-3-v-4-index-mdx": () => import("./../../../src/pages/articles/histogram-in-d3v3-vs-d3v4/index.mdx" /* webpackChunkName: "component---src-pages-articles-histogram-in-d-3-v-3-vs-d-3-v-4-index-mdx" */),
  "component---src-pages-articles-how-to-drive-react-state-with-d-3-transitions-for-complex-animation-index-mdx": () => import("./../../../src/pages/articles/how-to-drive-react-state-with-d3-transitions-for-complex-animation/index.mdx" /* webpackChunkName: "component---src-pages-articles-how-to-drive-react-state-with-d-3-transitions-for-complex-animation-index-mdx" */),
  "component---src-pages-articles-how-you-can-translate-any-random-d-3-example-to-react-index-mdx": () => import("./../../../src/pages/articles/how-you-can-translate-any-random-d3-example-to-react/index.mdx" /* webpackChunkName: "component---src-pages-articles-how-you-can-translate-any-random-d-3-example-to-react-index-mdx" */),
  "component---src-pages-articles-javascripts-most-popular-dataviz-library-index-mdx": () => import("./../../../src/pages/articles/javascripts-most-popular-dataviz-library/index.mdx" /* webpackChunkName: "component---src-pages-articles-javascripts-most-popular-dataviz-library-index-mdx" */),
  "component---src-pages-articles-kiran-challenge-index-mdx": () => import("./../../../src/pages/articles/kiran-challenge/index.mdx" /* webpackChunkName: "component---src-pages-articles-kiran-challenge-index-mdx" */),
  "component---src-pages-articles-livecoding-12-towards-animating-10-k-elements-with-react-index-mdx": () => import("./../../../src/pages/articles/livecoding-12-towards-animating-10k-elements-with-react/index.mdx" /* webpackChunkName: "component---src-pages-articles-livecoding-12-towards-animating-10-k-elements-with-react-index-mdx" */),
  "component---src-pages-articles-livecoding-13-rendering-react-components-with-canvas-index-mdx": () => import("./../../../src/pages/articles/livecoding-13-rendering-react-components-with-canvas/index.mdx" /* webpackChunkName: "component---src-pages-articles-livecoding-13-rendering-react-components-with-canvas-index-mdx" */),
  "component---src-pages-articles-livecoding-19-its-hard-to-package-a-library-index-mdx": () => import("./../../../src/pages/articles/livecoding-19-its-hard-to-package-a-library/index.mdx" /* webpackChunkName: "component---src-pages-articles-livecoding-19-its-hard-to-package-a-library-index-mdx" */),
  "component---src-pages-articles-livecoding-recap-a-new-more-versatile-react-pattern-index-mdx": () => import("./../../../src/pages/articles/livecoding-recap-a-new-more-versatile-react-pattern/index.mdx" /* webpackChunkName: "component---src-pages-articles-livecoding-recap-a-new-more-versatile-react-pattern-index-mdx" */),
  "component---src-pages-articles-loops-are-the-hardest-index-mdx": () => import("./../../../src/pages/articles/loops-are-the-hardest/index.mdx" /* webpackChunkName: "component---src-pages-articles-loops-are-the-hardest-index-mdx" */),
  "component---src-pages-articles-lumagl-pt-1-index-mdx": () => import("./../../../src/pages/articles/lumagl-pt1/index.mdx" /* webpackChunkName: "component---src-pages-articles-lumagl-pt-1-index-mdx" */),
  "component---src-pages-articles-mondrian-art-generator-mdx": () => import("./../../../src/pages/articles/mondrian-art-generator.mdx" /* webpackChunkName: "component---src-pages-articles-mondrian-art-generator-mdx" */),
  "component---src-pages-articles-money-spent-index-mdx": () => import("./../../../src/pages/articles/money-spent/index.mdx" /* webpackChunkName: "component---src-pages-articles-money-spent-index-mdx" */),
  "component---src-pages-articles-moores-law-mdx": () => import("./../../../src/pages/articles/moores-law.mdx" /* webpackChunkName: "component---src-pages-articles-moores-law-mdx" */),
  "component---src-pages-articles-mouse-events-with-d-3-v-6-react-hooks-index-mdx": () => import("./../../../src/pages/articles/mouse-events-with-d3v6-react-hooks/index.mdx" /* webpackChunkName: "component---src-pages-articles-mouse-events-with-d-3-v-6-react-hooks-index-mdx" */),
  "component---src-pages-articles-nbody-collision-simulation-with-react-d-3-and-mobx-index-mdx": () => import("./../../../src/pages/articles/nbody-collision-simulation-with-react-d3-and-mobx/index.mdx" /* webpackChunkName: "component---src-pages-articles-nbody-collision-simulation-with-react-d-3-and-mobx-index-mdx" */),
  "component---src-pages-articles-newsletter-dataviz-mdx": () => import("./../../../src/pages/articles/newsletter-dataviz.mdx" /* webpackChunkName: "component---src-pages-articles-newsletter-dataviz-mdx" */),
  "component---src-pages-articles-optimizing-react-choropleth-map-rendering-index-mdx": () => import("./../../../src/pages/articles/optimizing-react-choropleth-map-rendering/index.mdx" /* webpackChunkName: "component---src-pages-articles-optimizing-react-choropleth-map-rendering-index-mdx" */),
  "component---src-pages-articles-pirates-downfall-causes-global-warming-index-mdx": () => import("./../../../src/pages/articles/pirates-downfall-causes-global-warming/index.mdx" /* webpackChunkName: "component---src-pages-articles-pirates-downfall-causes-global-warming-index-mdx" */),
  "component---src-pages-articles-reactvr-react-360-is-great-but-maybe-not-quite-there-yet-index-mdx": () => import("./../../../src/pages/articles/reactvr-react-360-is-great-but-maybe-not-quite-there-yet/index.mdx" /* webpackChunkName: "component---src-pages-articles-reactvr-react-360-is-great-but-maybe-not-quite-there-yet-index-mdx" */),
  "component---src-pages-articles-responsive-svg-drawing-with-react-and-uselayouteffect-index-mdx": () => import("./../../../src/pages/articles/responsive-svg-drawing-with-react-and-uselayouteffect/index.mdx" /* webpackChunkName: "component---src-pages-articles-responsive-svg-drawing-with-react-and-uselayouteffect-index-mdx" */),
  "component---src-pages-articles-sankey-index-mdx": () => import("./../../../src/pages/articles/sankey/index.mdx" /* webpackChunkName: "component---src-pages-articles-sankey-index-mdx" */),
  "component---src-pages-articles-serverside-rendering-a-d-3-chart-with-react-16-index-mdx": () => import("./../../../src/pages/articles/serverside-rendering-a-d3-chart-with-react-16/index.mdx" /* webpackChunkName: "component---src-pages-articles-serverside-rendering-a-d-3-chart-with-react-16-index-mdx" */),
  "component---src-pages-articles-silky-smooth-piechart-transitions-with-react-and-d-3-js-index-mdx": () => import("./../../../src/pages/articles/silky-smooth-piechart-transitions-with-react-and-d3js/index.mdx" /* webpackChunkName: "component---src-pages-articles-silky-smooth-piechart-transitions-with-react-and-d-3-js-index-mdx" */),
  "component---src-pages-articles-smartphones-index-mdx": () => import("./../../../src/pages/articles/smartphones/index.mdx" /* webpackChunkName: "component---src-pages-articles-smartphones-index-mdx" */),
  "component---src-pages-articles-tiny-react-and-d-3-flamegraph-tutorial-index-mdx": () => import("./../../../src/pages/articles/tiny-react-and-d3-flamegraph-tutorial/index.mdx" /* webpackChunkName: "component---src-pages-articles-tiny-react-and-d-3-flamegraph-tutorial-index-mdx" */),
  "component---src-pages-articles-using-d-3-js-transitions-in-react-index-mdx": () => import("./../../../src/pages/articles/using-d3js-transitions-in-react/index.mdx" /* webpackChunkName: "component---src-pages-articles-using-d-3-js-transitions-in-react-index-mdx" */),
  "component---src-pages-articles-webgl-airplanes-index-mdx": () => import("./../../../src/pages/articles/webgl-airplanes/index.mdx" /* webpackChunkName: "component---src-pages-articles-webgl-airplanes-index-mdx" */),
  "component---src-pages-articles-when-to-buy-gifts-index-mdx": () => import("./../../../src/pages/articles/when-to-buy-gifts/index.mdx" /* webpackChunkName: "component---src-pages-articles-when-to-buy-gifts-index-mdx" */),
  "component---src-pages-articles-why-dataviz-does-better-for-your-career-than-todoapps-index-mdx": () => import("./../../../src/pages/articles/why-dataviz-does-better-for-your-career-than-todoapps/index.mdx" /* webpackChunkName: "component---src-pages-articles-why-dataviz-does-better-for-your-career-than-todoapps-index-mdx" */),
  "component---src-pages-building-blocks-1-mdx": () => import("./../../../src/pages/building-blocks/1.mdx" /* webpackChunkName: "component---src-pages-building-blocks-1-mdx" */),
  "component---src-pages-building-blocks-2-mdx": () => import("./../../../src/pages/building-blocks/2.mdx" /* webpackChunkName: "component---src-pages-building-blocks-2-mdx" */),
  "component---src-pages-building-blocks-3-mdx": () => import("./../../../src/pages/building-blocks/3.mdx" /* webpackChunkName: "component---src-pages-building-blocks-3-mdx" */),
  "component---src-pages-building-blocks-4-mdx": () => import("./../../../src/pages/building-blocks/4.mdx" /* webpackChunkName: "component---src-pages-building-blocks-4-mdx" */),
  "component---src-pages-building-blocks-5-mdx": () => import("./../../../src/pages/building-blocks/5.mdx" /* webpackChunkName: "component---src-pages-building-blocks-5-mdx" */),
  "component---src-pages-building-blocks-index-mdx": () => import("./../../../src/pages/building-blocks/index.mdx" /* webpackChunkName: "component---src-pages-building-blocks-index-mdx" */),
  "component---src-pages-cookbook-10-mdx": () => import("./../../../src/pages/cookbook/10.mdx" /* webpackChunkName: "component---src-pages-cookbook-10-mdx" */),
  "component---src-pages-cookbook-11-mdx": () => import("./../../../src/pages/cookbook/11.mdx" /* webpackChunkName: "component---src-pages-cookbook-11-mdx" */),
  "component---src-pages-cookbook-12-mdx": () => import("./../../../src/pages/cookbook/12.mdx" /* webpackChunkName: "component---src-pages-cookbook-12-mdx" */),
  "component---src-pages-cookbook-13-mdx": () => import("./../../../src/pages/cookbook/13.mdx" /* webpackChunkName: "component---src-pages-cookbook-13-mdx" */),
  "component---src-pages-cookbook-14-mdx": () => import("./../../../src/pages/cookbook/14.mdx" /* webpackChunkName: "component---src-pages-cookbook-14-mdx" */),
  "component---src-pages-cookbook-15-mdx": () => import("./../../../src/pages/cookbook/15.mdx" /* webpackChunkName: "component---src-pages-cookbook-15-mdx" */),
  "component---src-pages-cookbook-17-mdx": () => import("./../../../src/pages/cookbook/17.mdx" /* webpackChunkName: "component---src-pages-cookbook-17-mdx" */),
  "component---src-pages-cookbook-18-mdx": () => import("./../../../src/pages/cookbook/18.mdx" /* webpackChunkName: "component---src-pages-cookbook-18-mdx" */),
  "component---src-pages-cookbook-2-mdx": () => import("./../../../src/pages/cookbook/2.mdx" /* webpackChunkName: "component---src-pages-cookbook-2-mdx" */),
  "component---src-pages-cookbook-3-mdx": () => import("./../../../src/pages/cookbook/3.mdx" /* webpackChunkName: "component---src-pages-cookbook-3-mdx" */),
  "component---src-pages-cookbook-4-mdx": () => import("./../../../src/pages/cookbook/4.mdx" /* webpackChunkName: "component---src-pages-cookbook-4-mdx" */),
  "component---src-pages-cookbook-5-mdx": () => import("./../../../src/pages/cookbook/5.mdx" /* webpackChunkName: "component---src-pages-cookbook-5-mdx" */),
  "component---src-pages-cookbook-6-mdx": () => import("./../../../src/pages/cookbook/6.mdx" /* webpackChunkName: "component---src-pages-cookbook-6-mdx" */),
  "component---src-pages-cookbook-7-mdx": () => import("./../../../src/pages/cookbook/7.mdx" /* webpackChunkName: "component---src-pages-cookbook-7-mdx" */),
  "component---src-pages-cookbook-8-mdx": () => import("./../../../src/pages/cookbook/8.mdx" /* webpackChunkName: "component---src-pages-cookbook-8-mdx" */),
  "component---src-pages-cookbook-9-mdx": () => import("./../../../src/pages/cookbook/9.mdx" /* webpackChunkName: "component---src-pages-cookbook-9-mdx" */),
  "component---src-pages-cookbook-index-mdx": () => import("./../../../src/pages/cookbook/index.mdx" /* webpackChunkName: "component---src-pages-cookbook-index-mdx" */),
  "component---src-pages-d-3-quick-intro-2-mdx": () => import("./../../../src/pages/d3-quick-intro/2.mdx" /* webpackChunkName: "component---src-pages-d-3-quick-intro-2-mdx" */),
  "component---src-pages-d-3-quick-intro-6-mdx": () => import("./../../../src/pages/d3-quick-intro/6.mdx" /* webpackChunkName: "component---src-pages-d-3-quick-intro-6-mdx" */),
  "component---src-pages-d-3-quick-intro-index-mdx": () => import("./../../../src/pages/d3-quick-intro/index.mdx" /* webpackChunkName: "component---src-pages-d-3-quick-intro-index-mdx" */),
  "component---src-pages-finished-index-mdx": () => import("./../../../src/pages/finished/index.mdx" /* webpackChunkName: "component---src-pages-finished-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-introduction-2-mdx": () => import("./../../../src/pages/introduction/2.mdx" /* webpackChunkName: "component---src-pages-introduction-2-mdx" */),
  "component---src-pages-introduction-3-mdx": () => import("./../../../src/pages/introduction/3.mdx" /* webpackChunkName: "component---src-pages-introduction-3-mdx" */),
  "component---src-pages-introduction-4-mdx": () => import("./../../../src/pages/introduction/4.mdx" /* webpackChunkName: "component---src-pages-introduction-4-mdx" */),
  "component---src-pages-introduction-5-mdx": () => import("./../../../src/pages/introduction/5.mdx" /* webpackChunkName: "component---src-pages-introduction-5-mdx" */),
  "component---src-pages-introduction-7-mdx": () => import("./../../../src/pages/introduction/7.mdx" /* webpackChunkName: "component---src-pages-introduction-7-mdx" */),
  "component---src-pages-introduction-index-mdx": () => import("./../../../src/pages/introduction/index.mdx" /* webpackChunkName: "component---src-pages-introduction-index-mdx" */),
  "component---src-pages-local-environment-4-mdx": () => import("./../../../src/pages/local-environment/4.mdx" /* webpackChunkName: "component---src-pages-local-environment-4-mdx" */),
  "component---src-pages-local-environment-index-mdx": () => import("./../../../src/pages/local-environment/index.mdx" /* webpackChunkName: "component---src-pages-local-environment-index-mdx" */),
  "component---src-pages-react-d-3-2-mdx": () => import("./../../../src/pages/react-d3/2.mdx" /* webpackChunkName: "component---src-pages-react-d-3-2-mdx" */),
  "component---src-pages-react-d-3-7-mdx": () => import("./../../../src/pages/react-d3/7.mdx" /* webpackChunkName: "component---src-pages-react-d-3-7-mdx" */),
  "component---src-pages-react-d-3-index-mdx": () => import("./../../../src/pages/react-d3/index.mdx" /* webpackChunkName: "component---src-pages-react-d-3-index-mdx" */),
  "component---src-pages-react-hooks-1-mdx": () => import("./../../../src/pages/react-hooks/1.mdx" /* webpackChunkName: "component---src-pages-react-hooks-1-mdx" */),
  "component---src-pages-react-hooks-2-mdx": () => import("./../../../src/pages/react-hooks/2.mdx" /* webpackChunkName: "component---src-pages-react-hooks-2-mdx" */),
  "component---src-pages-react-hooks-3-mdx": () => import("./../../../src/pages/react-hooks/3.mdx" /* webpackChunkName: "component---src-pages-react-hooks-3-mdx" */),
  "component---src-pages-react-hooks-4-mdx": () => import("./../../../src/pages/react-hooks/4.mdx" /* webpackChunkName: "component---src-pages-react-hooks-4-mdx" */),
  "component---src-pages-speed-optimizations-canvas-game-mdx": () => import("./../../../src/pages/speed-optimizations/canvas-game.mdx" /* webpackChunkName: "component---src-pages-speed-optimizations-canvas-game-mdx" */),
  "component---src-pages-speed-optimizations-index-mdx": () => import("./../../../src/pages/speed-optimizations/index.mdx" /* webpackChunkName: "component---src-pages-speed-optimizations-index-mdx" */),
  "component---src-pages-speed-optimizations-smooth-animation-mdx": () => import("./../../../src/pages/speed-optimizations/smooth-animation.mdx" /* webpackChunkName: "component---src-pages-speed-optimizations-smooth-animation-mdx" */),
  "component---src-pages-speed-optimizations-using-canvas-1-mdx": () => import("./../../../src/pages/speed-optimizations/using-canvas-1.mdx" /* webpackChunkName: "component---src-pages-speed-optimizations-using-canvas-1-mdx" */),
  "component---src-pages-speed-optimizations-using-canvas-2-mdx": () => import("./../../../src/pages/speed-optimizations/using-canvas-2.mdx" /* webpackChunkName: "component---src-pages-speed-optimizations-using-canvas-2-mdx" */),
  "component---src-pages-state-app-structure-4-mdx": () => import("./../../../src/pages/state-app-structure/4.mdx" /* webpackChunkName: "component---src-pages-state-app-structure-4-mdx" */),
  "component---src-pages-state-app-structure-5-mdx": () => import("./../../../src/pages/state-app-structure/5.mdx" /* webpackChunkName: "component---src-pages-state-app-structure-5-mdx" */),
  "component---src-pages-state-app-structure-index-mdx": () => import("./../../../src/pages/state-app-structure/index.mdx" /* webpackChunkName: "component---src-pages-state-app-structure-index-mdx" */),
  "component---src-pages-tech-salaries-choropleth-map-mdx": () => import("./../../../src/pages/tech-salaries/choropleth-map.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-choropleth-map-mdx" */),
  "component---src-pages-tech-salaries-index-mdx": () => import("./../../../src/pages/tech-salaries/index.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-index-mdx" */),
  "component---src-pages-tech-salaries-load-parse-data-mdx": () => import("./../../../src/pages/tech-salaries/load-parse-data.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-load-parse-data-mdx" */),
  "component---src-pages-tech-salaries-make-it-work-mdx": () => import("./../../../src/pages/tech-salaries/make-it-work.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-make-it-work-mdx" */),
  "component---src-pages-tech-salaries-meta-info-mdx": () => import("./../../../src/pages/tech-salaries/meta-info.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-meta-info-mdx" */),
  "component---src-pages-tech-salaries-preloader-mdx": () => import("./../../../src/pages/tech-salaries/preloader.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-preloader-mdx" */),
  "component---src-pages-tech-salaries-salaries-histogram-mdx": () => import("./../../../src/pages/tech-salaries/salaries-histogram.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-salaries-histogram-mdx" */),
  "component---src-pages-tech-salaries-user-control-mdx": () => import("./../../../src/pages/tech-salaries/user-control.mdx" /* webpackChunkName: "component---src-pages-tech-salaries-user-control-mdx" */),
  "component---src-pages-thanks-basics-mdx": () => import("./../../../src/pages/thanks-basics.mdx" /* webpackChunkName: "component---src-pages-thanks-basics-mdx" */),
  "component---src-pages-thanks-extra-mdx": () => import("./../../../src/pages/thanks-extra.mdx" /* webpackChunkName: "component---src-pages-thanks-extra-mdx" */),
  "component---src-pages-thanks-full-mdx": () => import("./../../../src/pages/thanks-full.mdx" /* webpackChunkName: "component---src-pages-thanks-full-mdx" */),
  "component---src-pages-workshop-advanced-transitions-mdx": () => import("./../../../src/pages/workshop/advanced-transitions.mdx" /* webpackChunkName: "component---src-pages-workshop-advanced-transitions-mdx" */),
  "component---src-pages-workshop-blackbox-mdx": () => import("./../../../src/pages/workshop/blackbox.mdx" /* webpackChunkName: "component---src-pages-workshop-blackbox-mdx" */),
  "component---src-pages-workshop-d-3-101-mdx": () => import("./../../../src/pages/workshop/d3-101.mdx" /* webpackChunkName: "component---src-pages-workshop-d-3-101-mdx" */),
  "component---src-pages-workshop-full-integration-mdx": () => import("./../../../src/pages/workshop/full-integration.mdx" /* webpackChunkName: "component---src-pages-workshop-full-integration-mdx" */),
  "component---src-pages-workshop-hooks-101-mdx": () => import("./../../../src/pages/workshop/hooks-101.mdx" /* webpackChunkName: "component---src-pages-workshop-hooks-101-mdx" */),
  "component---src-pages-workshop-index-mdx": () => import("./../../../src/pages/workshop/index.mdx" /* webpackChunkName: "component---src-pages-workshop-index-mdx" */),
  "component---src-pages-workshop-manual-animation-mdx": () => import("./../../../src/pages/workshop/manual-animation.mdx" /* webpackChunkName: "component---src-pages-workshop-manual-animation-mdx" */),
  "component---src-pages-workshop-outro-mdx": () => import("./../../../src/pages/workshop/outro.mdx" /* webpackChunkName: "component---src-pages-workshop-outro-mdx" */),
  "component---src-pages-workshop-transitions-mdx": () => import("./../../../src/pages/workshop/transitions.mdx" /* webpackChunkName: "component---src-pages-workshop-transitions-mdx" */)
}

