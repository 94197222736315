import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/introduction"
          }}>{`Introduction`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/introduction/2"
            }}>{`What you need to know (2:16)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/introduction/3"
            }}>{`How to approach React for Dataviz (3:16)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/introduction/4"
            }}>{`Why React and D3 (0:22)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/introduction/5"
            }}>{`Buzzword soup explained`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/introduction/7"
            }}>{`What you'll build (2:30)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/d3-quick-intro"
          }}>{`D3 the good parts`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/d3-quick-intro/2"
            }}>{`3 key insights to learn D3 from scratch (14:23)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/d3-quick-intro/6"
            }}>{`Recap (1:49)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/react-d3"
          }}>{`How React makes D3 easier `}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/react-d3/2"
            }}>{`What about existing libraries? (6:19)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/react-d3/7"
            }}>{`When you shouldn't use a library (1:14)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/building-blocks"
          }}>{`The building blocks`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/building-blocks/1"
            }}>{`D3 blackbox components (8:22)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/building-blocks/2"
            }}>{`D3blackbox magic trick - render anything in 30sec (5:11)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/building-blocks/3"
            }}>{`Scalable components with full integration (17:44)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/building-blocks/4"
            }}>{`Extra flexibility with render props (6:36)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/building-blocks/5"
            }}>{`Super simplicity with React Hooks (18:10)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/state-app-structure"
          }}>{`A note about state and app structure`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/state-app-structure/4"
            }}>{`How to structure your app`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/state-app-structure/5"
            }}>{`Congratz!`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/local-environment"
          }}>{`Set up a local environment`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/local-environment/4"
            }}>{`Install extra dependencies (0:45)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/tech-salaries"
          }}>{`176,113 tech salaries visualized – a dataviz dashboard`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/preloader"
            }}>{`Show a Preloader (6:18)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/load-parse-data"
            }}>{`Load and parse your data (9:45)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/choropleth-map"
            }}>{`Render a choropleth map of the US (21:30)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/salaries-histogram"
            }}>{`Render a Histogram of salaries (16:40)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/meta-info"
            }}>{`Make it understandable with meta info (20:07)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/user-control"
            }}>{`Add user controls for exploration (28:02)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/tech-salaries/make-it-work"
            }}>{`Make it work in the real world 🚀 (10:04)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/animation"
          }}>{`Animation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/animation/ball-game"
            }}>{`Game loop animation with a bouncy ball (7:27)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/animation/simple-animation"
            }}>{`Use transitions for simple animation (7:55)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/animation/game-loops"
            }}>{`Powerful animation with transitions and game loops combined (24:40)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/animation/enter-update-exit"
            }}>{`Enter-update-exit animation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/animation/redux-animation"
            }}>{`Animating with React, Redux, and D3`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/speed-optimizations"
          }}>{`Speed optimizations`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/speed-optimizations/using-canvas-1"
            }}>{`Using canvas`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/speed-optimizations/using-canvas-2"
            }}>{`Declarative Canvas with Konva and react-konva`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/speed-optimizations/smooth-animation"
            }}>{`Smooth animation with 20,000+ elements`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/speed-optimizations/canvas-game"
            }}>{`Build a small interactive canvas game`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/finished"
          }}>{`You finished! 🚀`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/cookbook"
          }}>{`Cookbook projects`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/2"
            }}>{`Christmas trees sold in USA - an emoji barchart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/3"
            }}>{`Money spent on Christmas - a line chart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/4"
            }}>{`Christmas movies at the box office - horizontal bar chart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/5"
            }}>{`What Americans want for Christmas - horizontal stack chart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/6"
            }}>{`Christmas carols and their words - a word cloud`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/7"
            }}>{`Will you buy a christmas tree? - a pie chart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/8"
            }}>{`What goes in Christmas stockings - a piechart with tooltips`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/9"
            }}>{`When Americans buy Christmas presents - a curved line chart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/10"
            }}>{`When people buy candy - animated barchart with easing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/11"
            }}>{`A responsive stack chart of smartphone market share`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/12"
            }}>{`A Sankey diagram`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/13"
            }}>{`Try Uber's WebGL dataviz library`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/14"
            }}>{`Real-time WebGL map of all airplanes in the world`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/15"
            }}>{`A compound arc chart`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/16"
            }}>{`Which emails sparked joy – an animated timeline`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/17"
            }}>{`A barchart race visualizing Moore's Law`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/cookbook/18"
            }}>{`Building a Piet Mondrian art generator with treemaps`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      