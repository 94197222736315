export default {
  "/introduction": ["Demo"],
  "/introduction/*": ["Demo"],
  "/react-d3": ["Demo", "RDV_Basic", "RDV_Full", "RDV_AllExtras"],
  "/react-d3/2": ["Demo"],
  "/building-blocks": ["RDV_Basic", "RDV_Full", "RDV_AllExtras"],
  "/building-blocks/*": ["RDV_Basic", "RDV_Full", "RDV_AllExtras"],
  "/d3-quick-intro": ["RDV_Basic", "RDV_Full", "RDV_AllExtras"],
  "/d3-quick-intro/*": ["RDV_Basic", "RDV_Full", "RDV_AllExtras"],
  "/react-d3/*": ["RDV_Basic", "RDV_Full", "RDV_AllExtras"],
  "/animation": ["RDV_Full", "RDV_AllExtras"],
  "/animation/*": ["RDV_Full", "RDV_AllExtras"],
  "/cookbook": ["RDV_Full", "RDV_AllExtras"],
  "/cookbook/*": ["RDV_Full", "RDV_AllExtras"],
  "/downloadable-materials/*": ["RDV_Full", "RDV_AllExtras"],
  "/finished": ["RDV_Full", "RDV_AllExtras"],

  "/speed-optimizations": ["RDV_Full", "RDV_AllExtras"],
  "/speed-optimizations/*": ["RDV_Full", "RDV_AllExtras"],
  "/tech-salaries": ["RDV_Full", "RDV_AllExtras"],
  "/tech-salaries/*": ["RDV_Full", "RDV_AllExtras"],
  "/state-app-structure": ["RDV_Full", "RDV_AllExtras"],
  "/state-app-structure/*": ["RDV_Full", "RDV_AllExtras"],
  "/local-environment": ["RDV_Full", "RDV_AllExtras"],
  "/local-environment/*": ["RDV_Full", "RDV_AllExtras"],
}
