// import baseTheme from "course-platform/src/gatsby-plugin-theme-ui"
import { future } from "@theme-ui/presets"
import { toTheme } from "@theme-ui/typography"
import merge from "lodash.merge"
import typography from "typography-theme-fairy-gates"
import "typeface-work-sans"
import "typeface-quattrocento-sans"
import { courseTheme } from "@swizec/gatsby-theme-course-platform"

const theme = merge(future, toTheme(typography), courseTheme)

export default theme
