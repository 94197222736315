import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Link, Container } from "theme-ui";
import { Banner, NavGrid, Grid, LogoGrid, Title, JumpToCourse, Video } from "@swizec/gatsby-theme-course-platform";
import { TableOfContents } from "../../../components/Blocks/";
import Pricing from "./Blocks/Pricing/Pricing";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Container sx={{
      mt: 30,
      pb: 120
    }} mdxType="Container">
      <h2 {...{
        "id": "this-content-is-exclusive-for-students"
      }}>{`This content is exclusive for students`}</h2>
      <p>{`Please login to view this page.`}</p>
      <p>{`You can still become a student 👇`}</p>
    </Container>
    <Title h1 mdxType="Title"># React for Data Visualization</Title>
    <Container sx={{
      mt: [-0.5, -1, -1],
      mb: [4, 4, 5],
      textAlign: 'center'
    }} mdxType="Container">
      <p>{`Learn how to `}<strong parentName="p">{`build scalable dataviz components`}</strong>{` your whole team can understand with React for Data Visualization.`}</p>
    </Container>
    <Container sx={{
      textAlign: 'left'
    }} mdxType="Container">
      <Video id="CoTTJ-vR1Mc" mdxType="Video" />
      <p>{`React for Data Visualization gives you a `}<strong parentName="p">{`quick overview of the basics`}</strong>{` to get you started, followed by a `}<strong parentName="p">{`deep dive`}</strong>{` that `}<strong parentName="p">{`solidifies your knowledge`}</strong>{` through varied projects and examples. `}<strong parentName="p">{`Build working code`}</strong>{` that you can `}<strong parentName="p">{`show off to your friends, boss, and coworkers.`}</strong></p>
      <p>{`Learn the basics with `}<strong parentName="p">{`interactive examples right in your browser`}</strong>{` — no need to install anything. Forget about Npm and Webpack and Babel and Node. `}<strong parentName="p">{`Just React and D3.`}</strong></p>
      <p><strong parentName="p">{`Dive into complex projects`}</strong>{` that teach you how it all fits together. Build `}<strong parentName="p">{`interactive visualizations`}</strong>{`, create `}<strong parentName="p">{`animations`}</strong>{`, and build `}<strong parentName="p">{`fast performance with canvas`}</strong>{`. Learn everything there is to know about building `}<strong parentName="p">{`beautiful apps with React and D3.`}</strong></p>
      <p>{`From the very `}<strong parentName="p">{`basics of React and D3`}</strong>{`, to state handling with `}<strong parentName="p">{`Redux`}</strong>{` and `}<strong parentName="p">{`MobX`}</strong>{`, React alternatives like `}<strong parentName="p">{`Preact`}</strong>{` and `}<strong parentName="p">{`Inferno.`}</strong></p>
      <p>{`And the best part?`}</p>
      <p>{`It's all cut into bite sized pieces. Whether you've got 5 minutes or an hour, you can get through this course. That's a promise.`}</p>
    </Container>
    <Title h2 mdxType="Title">
# What's in React for Data Visualization
    </Title>
    <Container sx={{
      textAlign: 'left'
    }} mdxType="Container">
      <TableOfContents mdxType="TableOfContents" />
    </Container>
    <Title h2 mdxType="Title">
## Start owning features like a pro
    </Title>
    <Container sx={{
      textAlign: 'center',
      mb: [4, 4, 5]
    }} mdxType="Container">
      <p>{`Buy once forever yours.`}</p>
    </Container>
    <Pricing mdxType="Pricing" />
    <Container sx={{
      textAlign: 'center',
      mb: [4, 4, 5]
    }} mdxType="Container">
      <p>{`30 day moneyback guarantee.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      