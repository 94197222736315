import React, { useEffect, useReducer } from "react"
import styled from "@emotion/styled"
import {
  countryList,
  PriceBox,
  PriceBoxSpecial,
  priceReducer,
} from "@swizec/gatsby-theme-course-platform"
import FadeIn from "react-lazyload-fadein"
import { ParityPrice } from "bigmac-index-price-calculator"

const parityPrice = new ParityPrice(process.env.GATSBY_PARITY_IPSTACK_KEY, true)

const Wrapper = styled.div`
  margin: 7rem 0rem;
`
const WrapperBlock = styled.div`
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 940px) {
    font-size: 20px;
  }

  @media (max-width: 940px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

/* eslint-disable react-hooks/exhaustive-deps */
const BigBuyButton = ({ id, price, onload, coupon }) => {
  useEffect(() => onload(), [])

  const url = coupon
    ? `https://gumroad.com/l/${id}/${coupon}?wanted=true`
    : `https://gumroad.com/l/${id}?wanted=true`

  return (
    <a
      href={url}
      className="btn btn-grey btn-min-width gumroad-button"
      data-gumroad-single-product="true"
      target="_blank"
    >
      Buy now ${price}
    </a>
  )
}
/* eslint-enable react-hooks/exhaustive-deps */

export const FadeInButton = ({ id, price, coupon }) => {
  return (
    <FadeIn height={55} duration={150}>
      {(onload) => (
        <BigBuyButton id={id} coupon={coupon} price={price} onload={onload} />
      )}
    </FadeIn>
  )
}

const Pricing = () => {
  const [
    { tier1, tier2, tier3, location, adjusted, locationError },
    dispatch,
  ] = useReducer(priceReducer, {
    data: {
      tier1: 59,
      tier2: 179,
      tier3: 279,
      location: {},
      adjusted: false,
      locationError: false,
    },
  })

  const calcPrices = async () => {
    // parityPrice is internally memoized so this fires only 1 request

    try {
      let tier1 = await parityPrice.price(59)
      let tier2 = await parityPrice.price(179)
      let tier3 = await parityPrice.price(279)

      let adjusted = true
      const { location } = await parityPrice.priceWithLocation(tier1)

      if (tier1 >= 59) {
        tier1 = 59
        tier2 = 179
        tier3 = 279
        adjusted = false
      }

      dispatch({
        type: "loaded",
        data: {
          location,
          tier1,
          tier2,
          tier3,
          adjusted,
        },
      })
    } catch (e) {
      dispatch({
        type: "loaded",
        data: {
          location,
          tier1: 59,
          tier2: 179,
          tier3: 279,
          adjusted: false,
          locationError: true,
        },
      })
    }
  }

  useEffect(() => {
    calcPrices()
  }, [])

  const country = location && location.country_name
  const coupon =
    country && countryList.includes(country.toLowerCase())
      ? country
      : country && location.continent_code

  return (
    <Wrapper id="pricing">
      <WrapperBlock>
        <PriceBox
          price={
            <>
              <span className="curr">$</span>
              {tier1}
            </>
          }
          plan="Basics course"
          features={
            <div>
              D3 the good parts
              <br />
              How React makes D3 easier
              <br />
              Quick React+D3 integration
              <br />
              Scalable dataviz components with full integration
              <br />
              <b>3 extra projects you can use as a cookbook</b>
              <br />
              <b>​Monthly livestream of a real dataviz project</b>
              <br />
              <br />
              Money-back guarantee
              <br />
              Yours forever
            </div>
          }
          button={
            <FadeInButton
              id="Fqwwi"
              price={tier1}
              coupon={adjusted ? coupon : null}
            />
          }
          location={adjusted ? country : null}
          locationError={locationError}
        />
        <PriceBoxSpecial
          price={
            <>
              <span className="curr">$</span>
              {tier3}
            </>
          }
          plan={
            <div>
              <p>
                <strong>FULL COURSE</strong>
              </p>
              <p>
                <strong>+ 1on1 call</strong>
              </p>
            </div>
          }
          features={
            <div>
              <b>30min 1on1 call where we discuss your usecase</b>
              <br />
              <br />
              D3 the good parts
              <br />
              How React makes D3 easier
              <br />
              Quick React+D3 integration
              <br />
              Scalable dataviz components with full integration
              <br />
              React, D3, and hooks
              <br />
              Detailed walkthrough of a large dataviz dashboard project
              <br />
              Game loop animation
              <br />
              Animating with transitions
              <br />
              Hybrid animation for complex interactions
              <br />
              Enter/update/exit animation
              <br />
              Redux and MobX basics
              <br />
              Rendering to Canvas
              <br />
              Refactoring to React Hooks
              <br />
              <b>All extra projects you can use as a cookbook</b>
              <br />
              <b>​Monthly livestream of a real dataviz project</b>
              <br />
              <br />
              Money-back guarantee
              <br />
              Yours forever
            </div>
          }
          button={
            <FadeInButton
              id="Hnbtz"
              price={tier3}
              coupon={adjusted ? coupon : null}
            />
          }
          location={adjusted ? country : null}
          locationError={locationError}
        />
        <PriceBox
          price={
            <>
              <span className="curr">$</span>
              {tier2}
            </>
          }
          plan="FULL COURSE"
          features={
            <div>
              <br />
              D3 the good parts
              <br />
              How React makes D3 easier
              <br />
              Quick React+D3 integration
              <br />
              Scalable dataviz components with full integration
              <br />
              React, D3, and hooks
              <br />
              Detailed walkthrough of a large dataviz dashboard project
              <br />
              Game loop animation
              <br />
              Animating with transitions
              <br />
              Hybrid animation for complex interactions
              <br />
              Enter/update/exit animation
              <br />
              Redux and MobX basics
              <br />
              Rendering to Canvas
              <br />
              Refactoring to React Hooks
              <br />
              <b>14 extra projects you can use as a cookbook</b>
              <br />
              <b>​Monthly livestream of a real dataviz project</b>
              <br />
              <br />
              Money-back guarantee
              <br />
              Yours forever
            </div>
          }
          button={
            <FadeInButton
              id="KDLxE"
              price={tier2}
              coupon={adjusted ? coupon : null}
            />
          }
          location={adjusted ? country : null}
          locationError={locationError}
        />
      </WrapperBlock>
    </Wrapper>
  )
}

export default Pricing
