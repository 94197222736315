export default {
  RDV_Full: [
    {
      StartingRole: "RDV_Basic",
      GumroadCode: "vMvtg",
      Price: 120,
    },
  ],
}

/*

{"Role I want to upgrade to": [ 
    {"Role I'm at now" : "gumroad code"}
]}

*/
